import React, { CSSProperties } from 'react';
import { Image as DatoImage } from 'react-datocms';
import NextImage from 'next/legacy/image';

export enum ImageType {
    dato = 'dato',
    next = 'next',
    img = 'img',
}

export interface Props {
    image: any;
    priority?: boolean;
    className?: string;
    imageType?: ImageType;
    fadeFromColor?: string;
    objectFit?: CSSProperties['objectFit'];
    objectPosition?: CSSProperties['objectPosition'];
    style?: CSSProperties;
    alt?: string;
}


export const Image = (
    {
        image,
        priority = false,
        className = '',
        imageType = ImageType.dato,
        fadeFromColor = '#ffffff00',
        objectFit = undefined,
        objectPosition = undefined,
        style = {},
        alt = ''
    }: Props
) => {
    if (!image)
        return null;
    if (imageType === ImageType.dato) {
        // dato cms image
        if (image.responsiveImage) {
            return (
                <DatoImage
                    data={{ ...image?.responsiveImage, base64: null, bgColor: fadeFromColor }}
                    className={className}
                    objectFit={objectFit}
                    objectPosition={objectPosition}
                    priority={priority}
                    style={style}
                />
            );
        } else if (image.url) {
            // return <NextImage src={image.url} layout='intrinsic' width='100%' height='100%' className={className} />;
            return <img src={image.url} width='100%' height='100%' className={className} alt={image.alt} />;
        }
    } else if (imageType === ImageType.next) {
        // nextjs image
        const loading = 'eager';
        // <div style={{ width: '100%' }}>
        // </div>
        return (
            <NextImage src={image} className={className} />
        );
        // return (
        //     <NextImage src={image?.url} height={image?.height} width={image?.width} alt={image?.alt ?? image?.title} className={className} />
        // );
    } else {
        // <img> tag
        return (
            <img src={image?.url} height={image?.height ?? 'auto'} width={image?.width ?? 'auto'} alt={image?.alt ?? image?.title} className='bg-red-300' />
        );
    }
};

export default Image;
