import React from 'react';
import { NavbarButton } from "@/components/Views/Buttons";
// import MuzologyLogo from "@/assets/muzology-logo-mbl@2x.png";
import MuzologyLogo from '@/assets/muzology-logo-9-23.png';
import Image from 'next/legacy/image';
import { MUZOLOGY_SIGNUP_URL } from '@/lib/defaults';

const CustomNavbar = ({ id, isMobile = false, hideSignupButton = false, pricingURL = null, pricingCTA = null, pricingClassName = null }) => {
    return (
        <nav id={id} className='fixed z-[90] top-0 left-0 right-0 h-20 bg-[white] flex flex-row justify-between items-center'>

            <div className='w-full h-full flex flex-row justify-between items-center hero-max-w mx-auto px-4'>

                <div className='h-full p-2 flex-shrink flex justify-center items-center'>
                    <Image src={MuzologyLogo.src} height={60} width={215} alt='Muzology Heart Logo' className='max-h-[100%] max-w-full' />
                </div>

                {!hideSignupButton && <NavbarButton
                    id={id + '-start-free-trial'}
                    data-heap='parent-hero-start-free-trial'
                    className={`${pricingClassName ?? 'dark-orange-button'} purple-text px-8 [height:50px]`}
                    innerClassName='[fontSize:12px] sm:text-base balanced'
                    text={pricingCTA ?? 'Sign Up for 7-Day Free Trial'}
                    href={MUZOLOGY_SIGNUP_URL}
                />}

            </div>

        </nav>
    );
};

/*
export const CustomMobileNavbar = ({ pricingURL }) => {
    return (
        <nav className='fixed z-[90] top-0 left-0 right-0 h-20 bg-[white] flex flex-row justify-between items-center'>

            <div className='w-full h-full flex flex-row justify-between items-center hero-max-w mx-auto px-4'>

                <img src={MuzologyLogo.src} alt='Muzology Heart Logo' className='h-full p-2 flex-shrink-1' />

                <NavbarButton
                    id='start-free-trial'
                    data-heap='parent-hero-start-free-trial'
                    className='dark-orange-button purple-text px-8 [height:50px]'
                    innerClassName='[fontSize:12px] sm:text-base not-balanced'
                    text='START 7-DAY TRIAL'
                    href={pricingURL ?? '/checkout'}
                />

            </div>

        </nav>
    );
};
*/

export default CustomNavbar;
