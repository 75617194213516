import React from 'react';
import Button from 'components/ReusableUI/Button';
import { useRouter } from 'next/router';
import { useDispatchUi } from 'context/ui';
import { PlayButton } from 'components/PlayButton';
import { BlackHeading } from '@/components/Views/Heading';
import LargeButton from '@/components/Views/Buttons';
import { MUZOLOGY_SIGNUP_URL } from '@/lib/defaults';

interface Props {
    id?: string;
    videoLink?: string;
    heading?: string;
    background?: string;
    smallHeading?: string;
    subhead?: string;
    ctaSignupButton?: string;
    homepage?: boolean;
}

const FounderSection = ({ id, videoLink, heading, background, smallHeading, subhead, ctaSignupButton, homepage }: Props) => {
    const router = useRouter();
    const dispatch: any = useDispatchUi();
    const paddingTop = heading ? 'pt-[100px] lg:pt-[220px]' : '';
    return (
        <section id={id} style={{ background }} className={`${paddingTop} default-py px-4 sm:px-10`}>
            <div className='founders-panel mx-auto default-max-w px-6 lg:px-10 py-6 relative w-full'>
                <div className='flex flex-row w-full max-w-none lg:max-w-[60%]'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row ml-[-10px] lg:ml-0 pb-0'>
                            <div className='flex'>
                                <div className='flex relative right-0 top-0 '>
                                    <img className='lg:hidden w-[60px] h-auto m-auto' src='/assets/graphics/lana-avatar.png' alt='Lana Israel' />
                                </div>
                            </div>
                            <div className='flex items-center'>
                                {homepage ? (
                                    <BlackHeading className='ml-4 mr-[-10px] lg:mr-0 lg:ml-0 text-[#28294E] pb-4 mb-4 mt-4 lg:mt-0'>{heading}</BlackHeading>
                                ) : (
                                    <BlackHeading
                                        className='ml-4 mr-[-10px] lg:mr-0 lg:ml-0 text-transparent bg-clip-text bg-gradient-to-r from-[#E637A8] to-[#E68137] pb-4 mt-4 mb-4 lg:mt-0'>
                                        {heading}
                                    </BlackHeading>
                                )}
                            </div>
                        </div>
                        <p className='font-lg text-[#4B5563] font-light text-left w-full leading-6 mt-4 lg:mt-1'>{smallHeading}</p>
                        <p className='font-lg text-[#4B5563] font-light text-left w-full leading-6 mt-4 lg:mt-1'>{subhead}</p>
                        {homepage ? (
                            <div className='lg:max-w-[380px] xl:max-w-none'>
                                <div className='flex flex-col sm:flex-row gap-4 items-center justify-center lg:justify-start mt-4'>
                                    <LargeButton id={id + '-start-free-trial'} onClick={() => router.push('/learn-more')} className='dark-button w-[220px]'>
                                        LEARN MORE
                                    </LargeButton>
                                    <LargeButton
                                        id={id + '-watch-video'}
                                        className='bg-[#C13A7C] hover:bg-[#993264] w-[220px]'
                                        onClick={() => dispatch({ type: 'PLAY_VIDEO', payload: videoLink })}
                                        rightDelta={true}
                                        text='WATCH VIDEO'
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className='lg:max-w-[380px] xl:max-w-none'>
                                <div className='flex flex-col sm:flex-row gap-4 items-center justify-start mt-10 lg:mt-4'>
                                    <LargeButton
                                        id={id + '-watch-video'}
                                        className='dark-button w-[220px]'
                                        onClick={() => dispatch({ type: 'PLAY_VIDEO', payload: videoLink })}
                                        rightDelta={true}
                                        text='How it Works'
                                    />
                                    <LargeButton
                                        id={id + '-start-free-trial'}
                                        onClick={() => router.push(MUZOLOGY_SIGNUP_URL)}
                                        className='purple-button w-[220px]'
                                    >
                                        Start Free Trial Now
                                    </LargeButton>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className='absolute bottom-0 -right-12 lg:w-[390px] h-full hidden lg:flex'>
                    <img
                        id='lana'
                        src='/assets/graphics/lana-image.png'
                        className='absolute bottom-0 right-[54px] w-[100%]'
                        alt='Lana Israel'
                    />

                    {/*<PlayButton id={id + '-play-button'} videoLink={videoLink} parentStyle={null} onClick={null} />*/}
                </div>
                <div className='absolute bottom-3 right-[7%] text-white hidden lg:block'>
                    <div>DR. LANA ISRAEL</div>
                    <br />
                    <div className='-mt-3'>FOUNDER OF MUZOLOGY</div>
                </div>
            </div>
        </section>
    );
};

export default FounderSection;
