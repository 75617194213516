import { useDispatchUi } from 'context/ui';

interface Props {
    id?: string;
    parentStyle?: any;
    videoLink?: any;
    onClick?: any;
}

export const PlayButton = ({ id = null, videoLink = null, parentStyle = {}, onClick = null, className = '' }) => {
    const dispatch: any = useDispatchUi();

    return (
        <button
            id={id ?? 'play-button'}
            className={`play-btn rounded-play-btn ${className}`}
            onClick={onClick ? onClick : () => dispatch({ type: 'PLAY_VIDEO', payload: videoLink })}
            style={parentStyle}
        >
            <div className='triangle right'></div>
            <style jsx>
                {`
                    .play-btn {
                        z-index: 8;
                        width: 80px;
                        height: 80px;

                        // center this over its parent
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 64%;
                        bottom: 0;

                        transform: translate(0, -50%) scale(1.25);

                        @media (max-width: 1280px) {
                            transform: translate(0, -50%) scale(1.125);
                        }
                        @media (max-width: 1024px) {
                            transform: translate(0, -50%) scale(1);
                        }

                        //right: 10%;
                        //@media (max-width: 440px) {
                        //width: 50px;
                        //height: 50px;
                        //}

                        @media (max-width: 400px) {
                            top: 35%;
                        }
                        @media (max-width: 400px) {
                            top: 65%;
                        }
                        margin-left: auto;
                        margin-right: auto;
                    }
                `}
            </style>
        </button>
    );
};
