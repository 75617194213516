import React, { useState } from 'react';
import { FloatUpDown } from '../Animations';
import { useRouter } from 'next/router';
import { SmallButton, LargeButton } from '@/components/Views/Buttons';
import { useForm } from 'react-hook-form';
import { checkValidEmail } from '@/lib/muzology';
import axios from 'axios';
import { Heading2 } from '@/components/Views/Heading';

const MailingList = ({ background = null, id }) => {
    const router = useRouter();
    const [submitted, setSubmitted] = useState(false);

    const {
        register,
        trigger,
        handleSubmit,
        watch,
        formState: { errors: formErrors }
    } = useForm();

    const onSubmit = (data) => {
        axios.post('/api/email_signup/', data).then(() => {
            setSubmitted(true);
        });
    };

    //
    return (
        <div id='join-mailing-list' className='w-full font-Inter default-py' style={{ background }}>
            <div
                className={`
                    relative 
                    lg:bg-[url("/assets/backgrounds/bg-ovals-lightblue.svg")]
                    bg-cover sm:bg-auto bg-center bg-no-repeat
                    flex items-center 
                    mb-0 lg:mb-0
                    lg:h-[550px]
                    min-h-0 lg:min-h-[500px] md:min-h-[0]
                    p-[20px] lg:p-[20px]
                    pb-0
                `}
            >
                <div
                    className='ghost-inspector-hide hidden lg:block'
                    style={{ position: 'absolute', top: '16%', left: '94%', transform: 'rotate(15deg) scale(1.0)' }}
                >
                    <FloatUpDown delay={Math.random()} distance={-5}>
                        <img src='/assets/icons/icon-line-heart.svg' />
                    </FloatUpDown>
                </div>
                <div className='mx-auto w-full max-w-[1080px]'>
                    <div className='m-[10px] sm:m-[20px] bg-white px-12 py-10 rounded-md shadow-md'>
                        <div className='pb-0 md:pb-6 flex flex-col sm:flex-row bg-white'>
                            <div className='w-full'>
                                <Heading2 className='block sm:hidden text-left xs:text-left' style={{ textAlign: 'left' }}>
                                    <span className=''>Stay in the know. Become a Muzology insider!</span>
                                    <span
                                        className='text-[28px] sm:text-[40px] md:text-[45px]'
                                        style={{
                                            position: 'relative',
                                            top: '3px',
                                            left: '0px',
                                            lineHeight: '0px'
                                        }}
                                    >
                                        🤩
                                    </span>
                                </Heading2>
                                <h2
                                    className='hidden sm:block pr-0 sm:pr-12 font-medium font-Inter text-left text-[#131313] text-xl sm:text-2xl lg:text-4xl mt-0  '
                                    style={{ lineHeight: '1.4' }}
                                >
                                    <span className=''>
                                        Stay in the know.
                                        <br />
                                        Become a Muzology insider!
                                    </span>
                                    <span
                                        className='text-[28px] sm:text-[40px] md:text-[45px]'
                                        style={{
                                            position: 'relative',
                                            top: '8px',
                                            left: '8px',
                                            lineHeight: '0px'
                                        }}
                                    >
                                        🤩
                                    </span>
                                </h2>
                            </div>
                            <div className='w-full '>
                                {submitted ? (
                                    <div id='mailing-list-submitted-message' className='m-0 text-[#4B5563] text-base sm:text-lg font-normal mt-4 sm:mt-0'>
                                        <p className='block sm:hidden'>
                                            Congrats! You're now a Muzology VIP. Stay tuned for math tips and fun Muzology updates.
                                        </p>
                                        <p className='hidden sm:block mt-0'>Congrats! You're now a Muzology VIP.</p>
                                        <p className='hidden sm:block'>Stay tuned for math tips and fun Muzology updates.</p>
                                    </div>
                                ) : (
                                    <>
                                        <p className='hidden sm:block text-[#1F2937] font-semibold text-sm'>Become a VIP. Join our Mailing List Today</p>
                                        <p className='block sm:hidden text-[#1F2937] font-semibold text-sm'>Join our Mailing List Today</p>

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className='flex flex-col sm:flex-row max-w-[400px] mt-2 gap-4'>
                                                <input
                                                    placeholder='Email Address...'
                                                    {...register('email', {
                                                        required: true,
                                                        validate: { validEmail: checkValidEmail }
                                                    })}
                                                    className='w-full bg-white text-[#6B7280] border-solid border-[1.5px] border-[#D1D5DB] rounded-lg h-[40px] px-4 text-base focus:outline-none focus:ring-2 focus:ring-[#3B82F6] focus:border-transparent'
                                                />
                                                <SmallButton
                                                    className='hidden sm:block pink-button h-[38px]'
                                                    text='Register'
                                                    onClick={() => trigger()}
                                                    id={id + '-register'}
                                                />
                                                <LargeButton
                                                    className='block sm:hidden pink-button h-[38px]'
                                                    text='Register'
                                                    onClick={() => trigger()}
                                                    id={id + '-register'}
                                                />
                                            </div>
                                            <p className='font-light text-tiny text-[#4B5563]'>We value your privacy and do not sell or share your email.</p>
                                        </form>
                                    </>
                                )}
                                {/* <p className='text-[#9CA3AF] font-normal text-sm leading-7 max-w-[480px] mt-6'>
                            We’ll send you a link to our theme song (created by a GRAMMY-award winning music producer and Emmy-nominated choreographer).{' '}
                        </p> */}
                            </div>
                        </div>
                        <div className='hidden md:flex justify-between gap-8'>
                            <div className='flex-grow flex items-center'>
                                <img className='mr-1.5' src='/assets/icons/check-circle-purple.svg' />
                                <p className='font-light text-[14px] text-[#131313'>Be the first to see NEW math music videos</p>
                            </div>
                            <div className='flex-grow flex items-center'>
                                <img className='mr-1.5' src='/assets/icons/check-circle-purple.svg' />
                                <p className='font-light text-[14px] text-[#131313]'>Get FUN weekly math tips</p>
                            </div>
                            <div className='flex-grow flex items-center'>
                                <img className='mr-1.5' src='/assets/icons/check-circle-purple.svg' />
                                <p className='font-light text-[14px] text-[#131313]'>Learn about EXCITING math events</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MailingList;
