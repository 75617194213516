import React from 'react';
import TestimonialGrid from '@/components/TestimonialCarousel/TestimonialGrid';
import SignupBlock from '@/components/SignupBlock/SignupBlock';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { Layout } from '@/components/Layout';

const SecondaryPageLayout = (props) => {
    // console.log(props);
    const { components, page } = props;
    const { seo } = page;
    return (
        <Layout>
            <NextSeo title={seo?.title} description={seo?.description} />
            <Head>
                <meta name='keywords' content={page.keywords} />
            </Head>

            {props.children}

            <div className='bg-testimonial-gradient pb-20 '>
                {components.length > 0 && <TestimonialGrid heading={components[0].heading} testimonials={components[0].testimonials} />}
                {components.length > 1 && <SignupBlock {...components[1]} />}
            </div>
        </Layout>

    );
};

export default SecondaryPageLayout;
