import React from 'react';
import Image from 'next/legacy/image';
import { NormalText } from '@/components/Views/Text';
import LargeButton from '@/components/Views/Buttons';
import LeftImage from '../../public/assets/graphics/parentsCTA1b.png';
import RightImage from '../../public/assets/graphics/parentsCTA2.png';
import BalanceText from '@/components/BalanceText/BalanceText';
import { classText } from '@/components/ExploreMath/ExploreMath';

const ParentsCTA = (
    {
        id = '',
        headline = '',
        text = null,
        buttonText = '',
        buttonLink = '',
        buttonClassName = null,
        buttonOnClick = null,
        className = null,
        showLearnMore = false,
        style = {},
        buttons = []
    }) => {

    return (
        <div id={id} className={`${className} default-px default-py font-Inter relative`} style={style}>
            <div className='absolute inset-0 z-0 bg-wavy-bg bg-origin-border bg-cover bg-center bg-no-repeat opacity-20' />
            <div className='relative z-10 flex gap-3 flex-col lg:flex-row items-center justify-center default-max-w mx-auto'>
                <div className='-bg-green-400 w-full lg:w-1/4 flex-shrink flex justify-center lg:justify-start'>
                    <div
                        className='w-full relative pb-60 max-w-[300px] scale-[115%] xl:scale-[145%]  xl:translate-x-[-20px] 2xl:translate-x-[-50px] hidden lg:block'>
                        <Image src={LeftImage} aria-hidden='true' layout='fill' objectFit='contain' alt='left image' />
                    </div>
                </div>
                <div className='-bg-yellow-400 w-full lg:w-2/3 flex-grow'>
                    {/*<MediumHeading heading={headline} className='text-center text-base xs:text-xl sm:text-xl sm:whitespace-nowrap' />*/}

                    <div className='text-center text-2xl md:text-[28px] font-medium text-[#131313] whitespace-nowraps mt-3'>
                        <BalanceText>
                            {headline}
                        </BalanceText>
                    </div>


                    <NormalText className='text-center xs:text-xl'>
                        {text ?? 'Get started now'}
                    </NormalText>

                    <div className='flex justify-center gap-4 flex-col sm:flex-row items-center'>
                        <LargeButton
                            id={id + '-' + classText(buttonText)}
                            className={`w-[240px] ${buttonClassName ?? 'blue-button'}`}
                            text={buttonText}
                            href={buttonLink}
                            onClick={buttonOnClick}
                            rightArrow={true}
                        />

                        {showLearnMore && (
                            <LargeButton
                                id={id + '-learn-more'}
                                className='dark-button w-[240px]'
                                text='Learn More'
                                href='/learn-more'
                            />
                        )}

                        {buttons?.map((button, index) => (
                            <LargeButton
                                key={button.text}
                                id={id + '-' + classText(button.text)}
                                className={`w-[240px] ${button.className ?? 'blue-button'}`}
                                text={button.text}
                                href={button.href}
                                onClick={button.onClick}
                            >
                                {button.icon}
                            </LargeButton>
                        ))}

                    </div>
                </div>
                <div className='hidden lg:flex -bg-green-400 ml-0 -mr-0 lg:ml-4 lg:-mr-4  w-full lg:w-1/4 flex-shrink relative h-auto justify-center lg:justify-end mt-10 lg:mt-0'>
                    <div className='w-full relative pb-60 max-w-[300px] scale-[115%] xl:scale-[130%] hidden sm:block xl:translate-x-[20px] 2xl:translate-x-[50px] mt-4 md:mt-10 lg:mt-0'>
                        <Image src={RightImage} aria-hidden={true} layout='fill' objectFit='contain' alt='right image' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParentsCTA;
